import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Text,
  useDisclosure,
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Spinner,
  Center,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  Alert,
  AlertIcon,
  Image,
  Flex,
  Show,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/action/platform';
import { getToken } from '../../components/auth/UserData';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import colors from '../../assets/custom/colors';

export const Login = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage('pt');
  }, []);
  const [captchaValue, setCaptchaValue] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const [loading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  async function onSubmit(e) {
    let resp = await dispatch(
      login({
        userId: e.userId,
        secret: e.password,
      })
    ).unwrap();
    if (resp?.accessToken) {
      let logged = getToken();
      if (logged) {
        navigate('/');
      }
    } else {
      onOpen();
    }
  }

  const ErrorLogin = props => {
    return (
      <>
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />
          <AlertDialogContent backgroundColor={'white'}>
            <AlertDialogHeader>Login Portal</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              <Stack spacing={3}>
                <Alert status="error">
                  <AlertIcon />
                  {t('login.error')}
                </Alert>
              </Stack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                OK
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    );
  };

  return (
    <Box textAlign="center" fontSize="xl">
      <ErrorLogin />
      {loading && (
        <Center position="absolute" width={'100%'} height={'100%'}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      )}
      <Flex
        height="100vh"
        px={5}
        direction="row"
        justifyContent={'center'}
        alignItems="center"
        textAlign="center"
      >
        <Show justifyContent={'center'} breakpoint="(min-width: 400px)">
          <Image ml={12} mb={5} maxHeight="60vh" src="/img/Group-16.png" />
        </Show>
        <Container maxW="md" py={{ base: '12', md: '24' }}>
          <Stack spacing="6">
            <Stack spacing="6">
              <Stack
                spacing={{ base: '2', md: '3' }}
                justifyContent={'center'}
                alignItems="center"
                textAlign="center"
              >
                <Image mx={5} src="/img/logo-muevy.svg" />
              </Stack>
            </Stack>
          </Stack>
          <Box
            py={{ base: '0', sm: '8' }}
            px={{ base: '4', sm: '10' }}
            bg={{ base: 'transparent', sm: 'bg.surface' }}
            boxShadow={{ base: 'none', sm: 'md' }}
            borderRadius={{ base: 'none', sm: 'xl' }}
          >
            <Stack spacing="6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing="5">
                  <FormControl isInvalid={errors.userId}>
                    <FormLabel color={colors.black} htmlFor="userId">
                      {t('login.userId')}:
                    </FormLabel>
                    <Input
                      borderColor={colors.black}
                      id="userId"
                      placeholder={t('placeholders.userId')}
                      {...register('userId', {
                        required: 'UserID required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.userId && errors.userId.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.password}>
                    <FormLabel color={colors.black} htmlFor="password">
                      {t('login.password')}:
                    </FormLabel>
                    <InputGroup size="md">
                      <Input
                        pr="4.5rem"
                        type={show ? 'text' : 'password'}
                        placeholder={t('placeholders.password')}
                        borderColor={colors.black}
                        id="password"
                        {...register('password', {
                          required: 'Password required',
                        })}
                      />
                      <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? (
                            <ViewOffIcon></ViewOffIcon>
                          ) : (
                            <ViewIcon></ViewIcon>
                          )}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {errors.password && errors.password.message}
                    </FormErrorMessage>
                  </FormControl>

                  <Flex justifyContent={'center'}>
                    <ReCAPTCHA
                      sitekey="6LfAAxsqAAAAAKswXBTMTMOyRTHF6fWG7RFMR5xC"
                      onChange={v => setCaptchaValue(v)}
                    />
                  </Flex>
                </Stack>
                <Stack spacing="6" mt={12}>
                  <Button
                    isLoading={isSubmitting}
                    p={3}
                    fontSize={15}
                    type="submit"
                    isDisabled={!captchaValue}
                    boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                  >
                    Login
                  </Button>
                </Stack>
                <Text
                  cursor="pointer"
                  fontSize={12}
                  mt={3}
                  as="u"
                  color={colors.black}
                  onClick={() => navigate('/forgotPassword')}
                >
                {t('login.forgotPassword')}?
                </Text>
              </form>
            </Stack>
          </Box>
        </Container>
      </Flex>
    </Box>
  );
};
