import { createAsyncThunk } from '@reduxjs/toolkit';
import { getToken } from '../../components/auth/UserData';
import baseUrl from '../../config/config';
import axios from 'axios';

export const getMerchants = createAsyncThunk('merchant/get', async () => {
  const response = await axios.get(`${baseUrl}/portalapi/merchant`, {
    headers: {
      Authorization: `${getToken()}`,
    },
  });
  return response.data;
});

export const getMerchant = createAsyncThunk('merchant/get', async id => {
  const response = await axios.get(`${baseUrl}/portalapi/merchant/${id}`, {
    headers: {
      Authorization: `${getToken()}`,
    },
  });
  return response.data;
});

export const registerMerchant = createAsyncThunk(
  'merchant/register',
  async data => {
    data = {
      acquirerId: data.acquirerId,
      businessName: data.businessName,
      businessId: data.businessId,
      contactName: data.contactName,
      contactPhone: data.contactPhone,
      contactEmail: data.contactEmail,
      addresses: [
        {
          type: 'MAIN',
          street: data.street,
          number: data.number,
          complement: data.complement,
          zipCode: data.zipCode,
          city: data.city,
          state: data.state,
          country: data.country,
        },
      ],
    };
    const response = await axios.post(`${baseUrl}/portalapi/merchant`, data, {
      headers: {
        Authorization: `${getToken()}`,
      },
    });
    return response.data;
  }
);

export const deleteMerchant = createAsyncThunk('merchant/delete', async id => {
  try {
    const response = await axios.delete(`${baseUrl}/portalapi/merchant/${id}`, {
      headers: {
        Authorization: `${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {}
});
