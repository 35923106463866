import React, { useState } from 'react';
import {
  Image,
  Flex,
  Box,
  Card,
  Text,
  Stack,
  CardBody,
  Input,
  InputGroup,
  Divider,
  Button,
  VStack,
  StackDivider,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { forgotPassword, resetPassword } from '../../redux/action/users';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import colors from '../../assets/custom/colors';
import ReCAPTCHA from 'react-google-recaptcha';

export const ForgotPassword = () => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState(false);
  const [email, setEmail] = useState();
  const [token, setToken] = useState();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userAuth, setUserAuth] = useState(false);

  const handleRequest = async data => {
    if (!userAuth) {
      const resp = await dispatch(
        forgotPassword({
          acquirerId: 'ACQUIRER_ID',
          merchantId: 'MERCHANT_ID',
          userId: email,
        })
      ).unwrap();
      if (resp.status === 200) {
        toast({
          title: t(`toast.newPassword`),
          position: 'top',
          description: t(`toast.emailSend`),
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        handleAuth(true);
      } else {
        toast({
          title: t(`toast.newPassword`),
          position: 'top',
          description: t(`toast.userNotFound`),
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    } else {
      if (password === confirmPassword) {
        const resp = await dispatch(
          resetPassword({
            acquirerId: 'ACQUIRER_ID',
            merchantId: 'MERCHANT_ID',
            userId: email,
            otpToken: token,
            newSecret: password,
            confirmNewSecret: confirmPassword,
          })
        ).unwrap();
      } else {
        toast({
          title: t(`toast.newPassword`),
          position: 'top',
          description: t(`toast.passwordSame`),
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    }
  };

  const handleEmail = e => {
    setEmail(e.target.value);
  };

  const handleAuth = e => {
    setUserAuth(e);
  };

  const handlePasswordChange = e => setPassword(e.target.value);

  const handleTokenChange = e => setToken(e.target.value);

  const handleConfirmPasswordChange = e => setConfirmPassword(e.target.value);

  return (
    <Box textAlign="center" fontSize="xl">
      <Flex
        height="93vh"
        p={12}
        direction="row"
        justifyContent={'center'}
        alignItems="center"
        textAlign="center"
      >
        <Card maxW="sm">
          <CardBody>
            <Flex justifyContent={'center'} direction={'column'} mx={5}>
              <Image mx={5} maxHeight={50} src="/img/logo-muevy.svg" />
              <Text fontSize="sm" color={'black'}>
                {t('login.resetPassword')}
              </Text>
            </Flex>
            {!userAuth && (
              <>
                <Stack mt="6" spacing="3">
                  <Text mb={5} fontSize={12} color={'black'}>
                    {t('login.descForget')}
                  </Text>
                  <Text color={'black'}>Email:</Text>
                  <InputGroup my={3} Quantity>
                    <Input
                      value={email}
                      onChange={handleEmail}
                      borderColor={'black'}
                      _placeholder={{ color: 'black' }}
                      placeholder="Email"
                      color={'black'}
                    />
                  </InputGroup>
                </Stack>
                <Flex justifyContent={'center'}>
                  <ReCAPTCHA
                    size="compact"
                    sitekey="6LfAAxsqAAAAAKswXBTMTMOyRTHF6fWG7RFMR5xC"
                    onChange={value => setCaptchaValue(value)}
                  />
                </Flex>
              </>
            )}

            {userAuth && (
              <VStack
                divider={<StackDivider borderColor="gray.200" />}
                spacing={4}
                align="stretch"
              >
                <Flex
                  justifyContent={'start'}
                  alignItems={'start'}
                  direction={'column'}
                  my={3}
                >
                  <Text fontSize="sm" color={'black'}>
                    Token
                  </Text>
                  <Input
                    value={token}
                    onChange={handleTokenChange}
                    borderColor={'black'}
                    _placeholder={{ color: 'black' }}
                    color={'black'}
                    placeholder={t('placeholders.token')}
                  />
                </Flex>

                <Flex
                  justifyContent={'start'}
                  alignItems={'start'}
                  direction={'column'}
                  my={3}
                >
                  <Text fontSize="sm" color={'black'}>
                    {t('login.password')}
                  </Text>
                  <Input
                    borderColor={'black'}
                    _placeholder={{ color: 'black' }}
                    color={'black'}
                    placeholder={t('login.password')}
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </Flex>

                <Flex
                  justifyContent={'start'}
                  alignItems={'start'}
                  direction={'column'}
                  my={3}
                >
                  <Text fontSize="sm" color={'black'}>
                    {t('login.confirmPassword')}
                  </Text>
                  <Input
                    type="password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    borderColor={'black'}
                    _placeholder={{ color: 'black' }}
                    color={'black'}
                    placeholder={t('login.confirmPassword')}
                  />
                </Flex>
              </VStack>
            )}
          </CardBody>
          <Divider />
          <Flex
            direction="column"
            justifyContent={'center'}
            alignItems="center"
            textAlign="center"
            bgColor={'white'}
          >
            <Button
              cursor="pointer"
              fontSize={12}
              m={5}
              isDisabled={!captchaValue}
              onClick={handleRequest}
            >
              {t('login.requestNew')}
            </Button>

            <Button
              bgColor={'white'}
              color={colors.primary}
              _hover={{ bg: colors.primary, color: 'white', boxShadow: 'md' }}
              m={2}
              fontSize={12}
              cursor={'pointer'}
              onClick={() => navigate('/login')}
            >
              {t('buttons.goBack')}
            </Button>
          </Flex>
        </Card>
      </Flex>
    </Box>
  );
};
