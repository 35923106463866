import React, { useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  List,
  ListItem,
  Text,
  SimpleGrid,
  SkeletonText,
  Box,
} from '@chakra-ui/react';
import { Card, CardBody } from '../../components/Card/CardComponent';
import { Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../redux/action/dashboard';
import DataProcess from './DataProcess';

export const Dashboard = () => {
  const { t, i18n } = useTranslation();
  const [totalTransactions, setTotalTransactions] = useState({});
  const [checkTotalAmount, setCheckTotalAmount]   = useState({});
  const [totalAmount, setTotalAmount]             = useState({});
  const [loading, setLoading] = useState(true);
  const handleTotalTransactions = data => {

    setLoading(true);
    const statusTotalAmount = data.total_amount_by_currency_code && Object.keys(data.total_amount_by_currency_code).length > 0;
    
    setTotalTransactions(data);
    setCheckTotalAmount(statusTotalAmount);
    setTotalAmount(data.total_amount_by_currency_code);

    setLoading(false);

  };

  return (
    <Flex flexDirection="column" width="95vw" px={20} mt={6}>
      <Breadcrumb p={2} bg="#fef" color="#333">
        <BreadcrumbItem>
          <BreadcrumbLink href="/Home">
            <Text fontSize="20px">Dashboard /</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px" mt={2}>
        {[
          {
            label: 'qtdTransactions',
            quantity: totalTransactions.total_quantity_transactions,
            color: 'tomato',
            borderColor: '#1ea9ab',
            amount_by_currency_code: 0,

          },
          {
            label: 'approvedTransactions',
            quantity: totalTransactions.total_approved_transaction,
            color: 'royalblue',
            borderColor: '#1eab4d',
            amount_by_currency_code: 0,

          },
          {
            label: 'rejectedTransactions',
            quantity: totalTransactions.total_rejected_transactions,
            color: 'seagreen',
            borderColor: '#ab1e1e',
            amount_by_currency_code: 0,

          },
          {
            label: 'amountTransactions',
            color: 'Navy',
            borderColor: '#2c3333',
            amount_by_currency_code: checkTotalAmount ?  totalAmount : {"": "0"},

          },
        ].map((card, index) => (
          <Card
            key={index}
            overflow="hidden"
            variant="outline"
            borderRadius="lg"
            bg="white"
            borderLeft={`4px solid ${card.borderColor}`}
            borderRight="1px solid #ccc"
            borderTop="1px solid #ccc"
            borderBottom="1px solid #ccc"
            p={4}
            mb={4}
          >
            <CardBody>
              <Stat>
                <StatLabel>
                  <Text fontSize="lg">{t(`dashboard.${card.label}`)}</Text>
                  </StatLabel>
                  <StatNumber color={card.color}>

                      {loading ? ( 
                        <Box>
                          <SkeletonText mt='3' noOfLines={1}  skeletonHeight='3' />
                        </Box> 
                      ) : (
                        <List spacing={3}>
                          <ListItem fontSize={40}>{card.quantity}</ListItem>
                          {Object.entries(card.amount_by_currency_code).map(([currencyCode, amount]) => (
                            amount && currencyCode ? (
                                <ListItem fontSize={20}>
                                  <Text mb={2} >
                                    {currencyCode} {formatCurrency(amount.total, currencyCode)}
                                  </Text>

                                </ListItem>
                            ) : (
                              <ListItem ></ListItem>
                            )
                          ))}
                        </List>
                      )}
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
        ))}

      </SimpleGrid>

      <Flex direction="column" pt={{ base: '120px', md: '25px' }}>
        <DataProcess totalTransactionsCallback={handleTotalTransactions} />
      </Flex>
    </Flex>

  );
};
