import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import {
  Flex,
  Box,
  Text,
  Button,
  PinInputField,
  PinInput,
  HStack,
  Input,
  InputGroup,
  Grid,
  GridItem,
  Select,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  Stack,
  VStack,
  StackDivider,
  Icon,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { ArrowDownIcon } from '@chakra-ui/icons';
import colors from '../../../../assets/custom/colors';
import { getProducts } from '../../../../redux/action/products';
import { otp } from '../../../../redux/action/platform';
import { getUsername } from '../../../../components/auth/UserData';
import { getBalanceByProd, quoteUnitary } from '../../../../redux/action/oct';
import { useTranslation } from 'react-i18next';
import { getTransactions } from '../../../../redux/action/dashboard';
import moment from 'moment';

const formatValue = value => {
  const cleanedValue = value.replace(/\D/g, '');

  if (cleanedValue.length === 0) {
    return '0,00';
  }

  const decimalPart = cleanedValue.slice(-2).padStart(2, '0');
  const integerPart = cleanedValue.slice(0, -2).replace(/^0+/, '');
  const formattedIntegerPart = integerPart
    .split('')
    .reverse()
    .reduce((acc, digit, index) => {
      return digit + (index && index % 3 === 0 ? '.' : '') + acc;
    }, '');

  return `${formattedIntegerPart || '0'},${decimalPart}`;
};

export const FormUnitary = ({ sendType, nextStep, backStep, setFile }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [values, setValues] = useState([]);
  const [quote, setQuote] = useState('');
  const [quoteId, setQuoteId] = useState('');
  const [form, setForm] = useState([]);
  const [pin, setPin] = useState('');
  const [products, setProducts] = useState('');
  const [product, setProduct] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [onOpenQuote, setOpenQuote] = useState(false);
  const [balance, setBalance] = useState([]);
  const [amount, setAmount] = useState('0,00');
  const [error, setError] = useState(false);
  const inputRef = useRef(null);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    const products = async () => {
      try {
        const data = await dispatch(getProducts()).unwrap();
        setProducts(data);
      } catch (error) {}
    };
    products();
  }, []);

  useEffect(() => {
    const confirm = async () => {
      try {
        if (product) {
          const data = await dispatch(getBalanceByProd(product)).unwrap();
          setBalance(data.accounts);
        }
      } catch (error) {}
    };
    confirm();
  }, [confirm]);

  const handleChange = event => {
    const input = event.target;
    let inputValue = input.value.replace(/[^\d,]/g, '');
    if (inputValue.length > 8) {
      inputValue = inputValue.slice(0, 8);
    }

    const formattedValue = formatValue(inputValue);
    setAmount(formattedValue);

    if (formattedValue !== '0,00') {
      setError(false);
    }

    requestAnimationFrame(() => {
      if (inputRef.current) {
        inputRef.current.selectionStart = inputRef.current.selectionEnd =
          formattedValue.length;
      }
    });
  };

  const handleStep = async e => {
    const data = {
      ...values,
      productId: product,
      otpToken: pin,
      quoteId: quoteId,
      amount: Number(parseFloat(amount.replace(/\./g, '').replace(',', '.'))),
    };
    nextStep(data);
  };

  const handleBlur = () => {
    if (amount === '0,00' || amount.trim() === '') {
      setError(true);
    }
  };

  const handleCancel = async => {
    backStep();
  };

  const handleConfirm = async => {
    setConfirm(true);
  };

  const handleSelectChange = e => {
    setProduct(e.target.value);
  };

  const formattedAmount = (amount, currency) => {
    if (quote !== '') {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: currency,
      }).format(parseFloat(amount));
    }
  };

  const handleForm = async e => {
    setForm(e);
    let data = {
      productId: product,
      senderAccount: 'ewallet:474709;sp=ibcorretora',
      recipientAccount: e.recipientAccountNumber,
      sourceCurrencyCode: e.currency,
      paymentType: e.paymentType,
      destinationAmount: Number(
        parseFloat(amount.replace(/\./g, '').replace(',', '.'))
      ),
      destinationCountryCode: e.recipientCountryCode,
      destinationCurrencyCode: e.recipientCurrencyCode,
    };
    const q = await dispatch(quoteUnitary(data)).unwrap();
    if (q == undefined) {
      toast({
        title: t(`toast.quoteError`),
        position: 'top',
        description: t(`toast.quoteErrorDesc`),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    } else {
      setQuote(q);
      setQuoteId(q.quoteId);
      setOpenQuote(true);
    }
  };

  const handleCloseQuote = async e => {
    setOpenQuote(false);
  };

  const handleConfirmQuote = async e => {
    setValues(form);
    let data = {
      userId: getUsername(),
      transaction: 'OCT',
    };
    dispatch(otp(data));
    setOpenQuote(false);
    setQuote('');
    onOpen();
  };

  const handleExtract = async e => {
    const today = moment().format('YYYY-MM-DD');
    const transactionData = await dispatch(
      getTransactions({ dateFrom: today, dateTo: today })
    ).unwrap();
    if (transactionData.length >= 1) {
      const formated = transactionData.map(t => ({
        amount: t.amount,
        timestamp: t.timestamp,
        currency: t.curr,
      }));
      setTransactions(formated);
    }
  };

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      display={'flex'}
      minW={'60vw'}
    >
      <Text color={'black'} fontSize="2xl">
        {t('octUnitary.formUnitary.title')}
        <Divider borderColor={'black'} />
      </Text>
      <Text color={'#7e7477'} fontSize="md" my={5}>
        {t('octUnitary.formUnitary.subTitle')}
        <Divider borderColor={'#7e7477'} />
      </Text>

      {!confirm && (
        <Flex
          borderRadius={10}
          p={10}
          direction="column"
          pt={{ base: '120px', md: '25px' }}
        >
          <>
            <Box
              my={5}
              width={'100%'}
              boxShadow="lg"
              border={'1px solid #982549'}
              borderRadius={10}
              p={10}
            >
              <Text mb={3}>{t('labels.product')}:</Text>
              <Select
                placeholder={t('placeholders.product')}
                onChange={handleSelectChange}
              >
                {products
                  ? products.map(option => {
                      return (
                        <option key={option.id} value={option.id}>
                          {option.description}
                        </option>
                      );
                    })
                  : ''}
              </Select>
            </Box>
            <Box direction="row" justifyContent={'center'} display={'flex'}>
              <Button
                bgColor={'white'}
                color={colors.primary}
                border={'1px solid #982549'}
                fontSize={15}
                boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                onClick={backStep}
                ml={2}
                mr={1}
              >
                {t('buttons.cancel')}
              </Button>
              <Button
                onClick={handleConfirm}
                isDisabled={product === '' ? true : false}
              >
                {t('buttons.confirm')}
              </Button>
            </Box>
          </>
        </Flex>
      )}
      {confirm && product !== '' && (
        <>
          <Box
            pt={{ base: '120px', md: '25px' }}
            boxShadow="lg"
            borderRadius={10}
            p={8}
            maxW={'60vw'}
            border={'1px solid black'}
          >
            <Flex
              my={3}
              direction={'row'}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              width={'100%'}
            >
              {balance?.map(m => {
                const formattedAmount = new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: m.currency,
                }).format(parseFloat(m.amount));
                return (
                  <Box
                    bgColor={'#982549'}
                    border={'2px solid'}
                    borderColor={'white'}
                    borderRadius={10}
                    mt={3}
                    boxShadow="lg"
                    minW={'8vw'}
                    justifyContent={'center'}
                    display={'flex'}
                  >
                    <Text
                      onClick={() => handleExtract(true)}
                      color={'white'}
                      as={'b'}
                      p={5}
                    >
                      {formattedAmount}
                    </Text>
                    {/* <Popover>
                      <PopoverTrigger>
                      </PopoverTrigger>
                      <PopoverContent bgColor={'white'} color="black">
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader justifyContent={'between'}>
                          {t('popOver.extract')}
                        </PopoverHeader>
                        <PopoverBody> */}
                    {/* <Flex
                            justifyContent={'center'}
                            direction={'column'}
                            mr={5}
                            cursor={'pointer'}
                          >
                            {transactions.length >= 1
                              ? transactions.map(t => {
                                  return (
                                    <Flex
                                      direction={'row'}
                                      justifyContent="space-between"
                                    >
                                      <Text color={colors.primary}>
                                        -{t.amount}
                                      </Text>{' '}
                                      <Text>
                                        {moment(
                                          t.timestamp,
                                          'DD-MM-YYYY HH:mm:ss'
                                        ).format('DD/MM/YYYY HH:mm')}
                                      </Text>
                                    </Flex>
                                  );
                                })
                              : ''}
                          </Flex> */}
                    {/* </PopoverBody>
                      </PopoverContent>
                    </Popover> */}
                  </Box>
                );
              })}
            </Flex>
            <Divider />
            <form onSubmit={handleSubmit(handleForm)}>
              <Text fontSize="3xl" my={5}>
                {t('octUnitary.formUnitary.shippingDetails')}
              </Text>
              <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={2}>
                <Box>
                  <FormControl isInvalid={errors.recipientCurrencyCode}>
                    <FormLabel>
                      {t('octUnitary.formUnitary.recipientCurrencyCode')}
                    </FormLabel>
                    <Select
                      variant="outline"
                      colorScheme="whiteAlpha"
                      id="recipientCurrencyCode"
                      placeholder={t('placeholders.recipientCurrencyCode')}
                      {...register('recipientCurrencyCode', {
                        required: 'Currency Code required',
                      })}
                    >
                      <option value={'USD'}>USD</option>
                      <option value={'EUR'}>EUR</option>
                      <option value={'GBP'}>GBP</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.recipientCurrencyCode &&
                        errors.recipientCurrencyCode.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                {/* <Box>
                    <FormControl isInvalid={errors.type}>
                      <FormLabel>{t('octUnitary.formUnitary.type')}</FormLabel>
                      <Select
                        {...register('type', {
                          required: 'Type required',
                        })}
                        placeholder={t('placeholders.type')}
                      >
                        <option value={'BAN'}>BAN</option>
                        <option value={'IBAN'}>IBAN</option>
                        <option value={'EWALLET'}>EWALLET</option>
                      </Select>
                      <FormErrorMessage>
                        {errors.type && errewallet:474709;sp=ibcorretoraors.type.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box> */}

                <Box>
                  <FormControl isInvalid={error}>
                    <FormLabel>{t('octUnitary.formUnitary.amount')}</FormLabel>
                    <InputGroup>
                      <Input
                        ref={inputRef}
                        value={amount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="0,00"
                        textAlign="right"
                        variant="outline"
                      />
                    </InputGroup>
                    {error && (
                      <FormErrorMessage>Amount required</FormErrorMessage>
                    )}
                  </FormControl>
                </Box>

                <Box>
                  <FormControl isInvalid={errors.senderAccountNumber}>
                    <FormLabel>
                      {t('octUnitary.formUnitary.senderAccountNumber')}
                    </FormLabel>
                    <Input
                      isDisabled={true}
                      id="senderAccountNumber"
                      // {...register('senderAccountNumber', {
                      //   required: 'Sender account required',
                      // })}
                      value={'ewallet:474709;sp=ibcorretora'}
                      placeholder={t('placeholders.senderAccount')}
                    />
                    <FormErrorMessage>
                      {errors.senderAccountNumber &&
                        errors.senderAccountNumber.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                {/* 
                <Box>
                  <FormControl isInvalid={errors.currency}>
                    <FormLabel>
                      {t('octUnitary.formUnitary.currency')}
                    </FormLabel>
                    <Select
                      variant="outline"
                      colorScheme="whiteAlpha"
                      placeholder={t('placeholders.option')}
                      {...register('currency', {
                        required: 'Currency required',
                      })}
                    >
                      <option value={'USD'}>USD</option>
                      <option value={'EUR'}>EUR</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.currency && errors.currency.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box> */}

                <GridItem colSpan={3} borderColor="#dae3e9">
                  <Divider />
                </GridItem>

                {/* <Box>
                  <FormControl>
                    <FormLabel>
                      {t('octUnitary.formUnitary.recipientAccountName')}
                    </FormLabel>
                    <Input
                      id="recipientAccountName"
                      {...register('recipientAccountName')}
                      placeholder={t('placeholders.recipientAccountName')}
                    />
                  </FormControl>
                </Box> */}

                <Box>
                  <FormControl isInvalid={errors.recipientAccountNumber}>
                    <FormLabel>
                      {t('octUnitary.formUnitary.recipientAccountNumber')}
                    </FormLabel>
                    <Input
                      id="recipientAccountNumber"
                      {...register('recipientAccountNumber', {
                        required: 'Recipient account number required',
                      })}
                      placeholder={t('placeholders.recipientAccountNumber')}
                    />
                    <FormErrorMessage>
                      {errors.recipientAccountNumber &&
                        errors.recipientAccountNumber.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <Box>
                  <FormControl isInvalid={errors.paymentType}>
                    <FormLabel>
                      {t('octUnitary.formUnitary.paymentType')}
                    </FormLabel>
                    <Select
                      variant="outline"
                      colorScheme="whiteAlpha"
                      placeholder={t('placeholders.paymentType')}
                      {...register('paymentType', {
                        required: 'Payment type required',
                      })}
                    >
                      <option value={'B2B'}>B2B</option>
                      <option value={'B2P'}>B2P</option>
                      <option value={'P2P'}>P2P</option>
                      <option value={'P2B'}>P2B</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.paymentType && errors.paymentType.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <Box>
                  <FormControl>
                    <FormLabel>
                      {t('octUnitary.formUnitary.recipientBankCode')}
                    </FormLabel>
                    <Input
                      id="recipientBankCode"
                      {...register('recipientBankCode')}
                      placeholder={t('placeholders.recipientBankCode')}
                    />
                  </FormControl>
                </Box>
                <GridItem colSpan={3} borderColor="#dae3e9">
                  <Divider />
                </GridItem>

                {/* 
                <Box>
                  <FormControl>
                    <FormLabel>
                      {t('octUnitary.formUnitary.recipientBankCodeType')}
                    </FormLabel>
                    <Input
                      id="recipientBankCodeType"
                      {...register('recipientBankCodeType')}
                      placeholder={t('placeholders.recipientBankCodeType')}
                    />
                  </FormControl>
                </Box>

                <Box>
                  <FormControl>
                    <FormLabel>
                      {t('octUnitary.formUnitary.recipientBankName')}
                    </FormLabel>
                    <Input
                      id="recipientBankName"
                      {...register('recipientBankName')}
                      placeholder={t('placeholders.recipientBankName')}
                    />
                  </FormControl>
                </Box>
*/}
                {/* <GridItem colSpan={3} borderColor="#dae3e9">
                  <Divider />
                </GridItem> */}
              </Grid>

              <Text fontSize="3xl" my={5}>
                {t('octUnitary.formUnitary.senderDetails')}
              </Text>
              <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                <Box>
                  <FormControl isInvalid={errors.senderFirstName}>
                    <FormLabel>
                      {' '}
                      {t('octUnitary.formUnitary.firstName')}
                    </FormLabel>
                    <Input
                      id="senderFirstName"
                      {...register('senderFirstName', {
                        required: 'Sender first name required',
                      })}
                      placeholder={t('placeholders.senderFirstName')}
                    />
                    <FormErrorMessage>
                      {errors.senderFirstName && errors.senderFirstName.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <Box>
                  <FormControl isInvalid={errors.senderLastName}>
                    <FormLabel>
                      {' '}
                      {t('octUnitary.formUnitary.lastName')}
                    </FormLabel>
                    <Input
                      id="senderLastName"
                      {...register('senderLastName', {
                        required: 'Sender last name required',
                      })}
                      placeholder={t('placeholders.senderLastName')}
                    />

                    <FormErrorMessage>
                      {errors.senderLastName && errors.senderLastName.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <GridItem colSpan={3} borderColor="#dae3e9">
                  <Divider />
                </GridItem>

                <Box>
                  <FormControl isInvalid={errors.senderAddressLine}>
                    <FormLabel>
                      {t('octUnitary.formUnitary.senderAddressLine')}
                    </FormLabel>
                    <Input
                      id="senderAddressLine"
                      {...register('senderAddressLine', {
                        required: 'Address Line required',
                      })}
                      placeholder={t('placeholders.senderAddressLine')}
                    />
                    <FormErrorMessage>
                      {errors.senderAddressLine &&
                        errors.senderAddressLine.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl isInvalid={errors.senderAddressCountry}>
                    <FormLabel>
                      {t('octUnitary.formUnitary.senderAddressCountry')}
                    </FormLabel>
                    <Input
                      id="senderAddressCountry"
                      {...register('senderAddressCountry', {
                        required: 'Sender address country required',
                      })}
                      placeholder={t('placeholders.senderAddressCountry')}
                    />
                    <FormErrorMessage>
                      {errors.senderAddressCountry &&
                        errors.senderAddressCountry.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                {/* <Box>
                  <FormControl>
                    <FormLabel>
                      {t('octUnitary.formUnitary.senderCity')}
                    </FormLabel>
                    <Input
                      id="senderCity"
                      {...register('senderCity')}
                      placeholder={t('placeholders.senderCity')}
                    />
                  </FormControl>
                </Box> */}
                <GridItem colSpan={3} borderColor="#dae3e9">
                  <Divider />
                </GridItem>
              </Grid>

              <GridItem my={4} colSpan={3} borderColor="#dae3e9">
                <Text fontSize="3xl">
                  {' '}
                  {t('octUnitary.formUnitary.recipientDetails')}
                </Text>
              </GridItem>
              <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                <Box>
                  <FormControl isInvalid={errors.recipientFirstName}>
                    <FormLabel>
                      {' '}
                      {t('octUnitary.formUnitary.firstName')}
                    </FormLabel>
                    <Input
                      id="recipientFirstName"
                      {...register('recipientFirstName', {
                        required: 'Recipient first name required',
                      })}
                      placeholder={t('placeholders.recipientFirstName')}
                    />
                    <FormErrorMessage>
                      {errors.recipientFirstName &&
                        errors.recipientFirstName.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <Box>
                  <FormControl isInvalid={errors.recipientLastName}>
                    <FormLabel>
                      {' '}
                      {t('octUnitary.formUnitary.lastName')}
                    </FormLabel>
                    <Input
                      id="recipientLastName"
                      {...register('recipientLastName', {
                        required: 'Recipient last name required',
                      })}
                      placeholder={t('placeholders.recipientLastName')}
                    />
                    <FormErrorMessage>
                      {errors.recipientLastName &&
                        errors.recipientLastName.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl isInvalid={errors.recipientCountryCode}>
                    <FormLabel>
                      {' '}
                      {t('octUnitary.formUnitary.recipientCountryCode')}
                    </FormLabel>
                    <Select
                      id="recipientCountryCode"
                      {...register('recipientCountryCode', {
                        required: 'Country required',
                      })}
                      placeholder={t('placeholders.recipientCountryCode')}
                    >
                      <option value={'USA'}>USA</option>
                      <option value={'PRT'}>PRT</option>
                      <option value={'BRA'}>BRA</option>
                      <option value={'GBR'}>GBR</option>
                      <option value={'CAN'}>CAN</option>
                      <option value={'FRA'}>FRA</option>
                      <option value={'DEU'}>DEU</option>
                      <option value={'JPN'}>JPN</option>
                      <option value={'CHN'}>CHN</option>
                      <option value={'IND'}>IND</option>
                      <option value={'AUS'}>AUS</option>
                      <option value={'RUS'}>RUS</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.recipientCountryCode &&
                        errors.recipientCountryCode.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <GridItem colSpan={3} borderColor="#dae3e9">
                  <Divider />
                </GridItem>

                <Box>
                  <FormControl>
                    <FormLabel>
                      {' '}
                      {t('octUnitary.formUnitary.recipientState')}
                    </FormLabel>
                    <Input
                      id="recipientState"
                      {...register('recipientState')}
                      placeholder={t('placeholders.recipientState')}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl>
                    <FormLabel>
                      {' '}
                      {t('octUnitary.formUnitary.recipientCity')}
                    </FormLabel>
                    <Input
                      id="recipientCity"
                      {...register('recipientCity')}
                      placeholder={t('placeholders.recipientCity')}
                    />
                  </FormControl>
                </Box>

                <Box>
                  <FormControl isInvalid={errors.recipientAddressLine}>
                    <FormLabel>
                      {' '}
                      {t('octUnitary.formUnitary.recipientAddressLine')}
                    </FormLabel>
                    <Input
                      id="recipientAddressLine"
                      {...register('recipientAddressLine', {
                        required: 'Address line required',
                      })}
                      placeholder={t('placeholders.recipientAddressLine')}
                    />
                    <FormErrorMessage>
                      {errors.recipientAddressLine &&
                        errors.recipientAddressLine.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <GridItem colSpan={3} borderColor="#dae3e9">
                  <Divider />
                </GridItem>

                <Box>
                  <FormControl>
                    <FormLabel>
                      {' '}
                      {t('octUnitary.formUnitary.recipientPostalCode')}
                    </FormLabel>
                    <Input
                      id="recipientPostalCode"
                      {...register('recipientPostalCode')}
                      placeholder={t('placeholders.recipientPostalCode')}
                    />
                  </FormControl>
                </Box>

                {/* <Box>
                  <FormControl>
                    <FormLabel>
                      {' '}
                      {t('octUnitary.formUnitary.recipientCountry')}
                    </FormLabel>
                    <Input
                      id="recipientCountry"
                      {...register('recipientCountry')}
                      placeholder={t('placeholders.recipientCountry')}
                    />
                  </FormControl>
                </Box> */}
              </Grid>
              <Box
                justifyContent="center"
                alignItems="center"
                display="flex"
                flexDirection="row"
                py={5}
                borderRadius={10}
                mb={5}
              >
                <Button
                  cursor="pointer"
                  bgColor={'white'}
                  color={colors.primary}
                  border={'1px solid #982549'}
                  onClick={handleCancel}
                  m={3}
                  fontSize={15}
                  boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                >
                  {t('buttons.cancel')}
                </Button>
                <Button
                  isLoading={isSubmitting}
                  onClick={handleBlur}
                  type="submit"
                >
                  {t('buttons.confirm')}
                </Button>
              </Box>
            </form>
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent bgColor="white">
                <ModalHeader>
                  <Text>Confirm Transaction</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text>Are you sure you want to send this transaction?</Text>

                  <Flex
                    direction={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    my={8}
                  >
                    <HStack>
                      <PinInput value={pin} onChange={value => setPin(value)}>
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                      </PinInput>
                    </HStack>
                    <Text mt={5} color={'#7e7477'} fontSize={'sm'}>
                      An OTP has been send to your email address
                    </Text>
                  </Flex>
                </ModalBody>

                <ModalFooter>
                  <Button
                    cursor="pointer"
                    bgColor={'white'}
                    color={colors.primary}
                    border={'1px solid #982549'}
                    m={3}
                    boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                    onClick={onClose}
                  >
                    Close
                  </Button>
                  <Button isDisabled={!pin} onClick={handleStep}>
                    Confirm Transaction
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <Modal isCentered isOpen={onOpenQuote} onClose={handleCloseQuote}>
              <ModalOverlay />
              <ModalContent bgColor="white">
                <ModalHeader>
                  <Text>Quote</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Flex
                    direction={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    my={8}
                  >
                    <Stack direction="column" spacing={4} align="center">
                      <Box
                        width="20vw"
                        minH={'10vh'}
                        color="#f7f5f5"
                        border={'1px solid #dae3e9'}
                        borderRadius="md"
                        borderColor={''}
                        display="flex"
                        alignItems="center"
                        boxShadow="lg"
                        mx={5}
                      >
                        <Flex
                          direction={'column'}
                          width={'100%'}
                          alignItems="start"
                        >
                          <Text color={'#7e7477'} p={3} fontSize={'sm'}>
                            Amount to be sent
                          </Text>
                          <Divider borderColor={'gray.300'} />

                          <Box
                            display="flex"
                            width={'100%'}
                            justifyContent="space-between"
                            alignItems={'center'}
                          >
                            <Text p={3} color={'black'} fontSize={'2xl'}>
                              {quote !== '' &&
                                formattedAmount(
                                  quote?.destinationAmount,
                                  quote?.settlementCurrencyCode
                                )}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>

                      <Box
                        borderRadius={20}
                        bg={'#982549'}
                        display={'flex'}
                        alignContent={'center'}
                      >
                        <Icon
                          color={'white'}
                          m={1}
                          as={ArrowDownIcon}
                          w={4}
                          h={4}
                        />
                      </Box>

                      <Box
                        width="20vw"
                        minH={'10vh'}
                        borderColor={'gray.400'}
                        border={'1px solid #dae3e9'}
                        color="#f7f5f5"
                        borderRadius="md"
                        display="flex"
                        alignItems="center"
                        justifyContent={'space-between'}
                        boxShadow="lg"
                      >
                        <Flex
                          direction={'column'}
                          width={'100%'}
                          alignItems="start"
                        >
                          <Text color={'#7e7477'} p={3} fontSize={'sm'}>
                            Amount to be paid
                          </Text>
                          <Divider borderColor={'gray.300'} />
                          <Box
                            display="flex"
                            width={'100%'}
                            justifyContent="space-between"
                            alignItems={'center'}
                          >
                            <Text p={3} color={'black'} fontSize={'2xl'}>
                              {quote &&
                                formattedAmount(
                                  quote?.settlementAmount,
                                  quote?.destinationCurrencyCode
                                )}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>

                      <Box
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                      >
                        <VStack
                          divider={<StackDivider borderColor="gray.200" />}
                          spacing={4}
                          align="stretch"
                        >
                          <Box
                            width={'20vw'}
                            display={'flex'}
                            justifyContent={'space-between'}
                          >
                            <Text>
                              Fx Rate <Icon />
                            </Text>
                            <Text>{quote?.fxRate}</Text>
                          </Box>
                        </VStack>
                      </Box>
                    </Stack>
                  </Flex>
                </ModalBody>

                <ModalFooter>
                  <Button
                    cursor="pointer"
                    bgColor={'white'}
                    color={colors.primary}
                    border={'1px solid #982549'}
                    m={3}
                    boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                    onClick={handleCloseQuote}
                  >
                    Close
                  </Button>
                  <Button onClick={handleConfirmQuote}>Confirm Quote</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>
        </>
      )}
    </Flex>
  );
};
