import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Flex,
  Box,
  Text,
  Button,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Divider,
  useToast,
} from '@chakra-ui/react';
import { ViewIcon, SearchIcon } from '@chakra-ui/icons';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  confirmBatch,
  getBalanceByProd,
  getBatches,
  getTransactionsByBatch,
  quoteBatch,
} from '../../../../redux/action/oct';
import { getProducts } from '../../../../redux/action/products';
import { useTranslation } from 'react-i18next';
import colors from '../../../../assets/custom/colors';
import moment from 'moment';

export const GridList = ({ values, newTransaction, nextStep, detail }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast();
  const [batchesQueue, setBatchesQueue] = useState([]);
  const [processedBatches, setProcessedBatches] = useState([]);
  const [openQuote, setOpenQuote] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quote, setQuote] = useState(false);
  const [options, setOptions] = useState([]);
  const [balance, setBalance] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [selectedOp, setSelectedOp] = useState('');
  const [selectedBatch, setSelectedBatch] = useState([]);
  const ViewButtonTemplate = rowData => {
    return (
      <Box width={'10px'}>
        <ViewIcon
          marginLeft={4}
          cursor={'pointer'}
          onClick={() => handleDetail(rowData)}
        />
      </Box>
    );
  };

  const QuoteButtonTemplate = rowData => {
    return (
      <Box width={'10px'}>
        <SearchIcon
          marginLeft={4}
          cursor={'pointer'}
          onClick={() => handleQuote(rowData)}
        />
      </Box>
    );
  };
  const columns = [
    { field: 'batchFileId', header: 'id', sortable: true },
    { field: 'status', header: 'status', sortable: true },
    { field: 'timestamp', header: 'timeStamp', sortable: true },
    {
      field: 'iconView',
      color: 'black',
      header: 'iconView',
      sortable: false,
      body: ViewButtonTemplate,
    },
    {
      field: 'iconQuote',
      color: 'black',
      header: 'iconQuote',
      sortable: false,
      body: QuoteButtonTemplate,
    },
  ];

  const columnsProcessed = [
    { field: 'batchFileId', header: 'id', sortable: true },
    { field: 'status', header: 'status', sortable: true },
    { field: 'timestamp', header: 'timeStamp', sortable: true },
    {
      field: 'iconView',
      color: 'black',
      header: 'iconView',
      sortable: false,
      body: ViewButtonTemplate,
    },
  ];

  const columnsTransactions = [
    {
      field: 'batchFileTransactionId',
      header: 'Transaction ID',
      sortable: true,
    },
    { field: 'amount', header: 'Amount', sortable: true },
    { field: 'amountQuoted', header: 'Amount Quoted', sortable: true },
    { field: 'status', header: 'Status', sortable: true },
    { field: 'timestamp', header: 'Send At', sortable: true },
  ];

  useEffect(() => {
    const confirm = async () => {
      try {
        if (selectedOp !== '') {
          const data = await dispatch(getBalanceByProd(selectedOp)).unwrap();
          setBalance(data.accounts);
        }
      } catch (error) {}
    };
    confirm();
  }, [selectedOp]);

  useEffect(() => {
    if (selectedOp !== '') {
      const batchesQueue = async () => {
        try {
          const data = await dispatch(getBatches(selectedOp)).unwrap();
          data?.queue.data.map(
            x => (x.timestamp = moment(x.timestamp).format('DD/MM/YYYY'))
          );
          if (data.processed.data.lenght > 0) {
            data?.processed.map(
              x => (x.timestamp = moment(x.timestamp).format('DD/MM/YYYY'))
            );
            setProcessedBatches(data.processed.data);
          }
          setBatchesQueue(data.queue.data);
        } catch (error) {}
      };

      batchesQueue();
    }
  }, [selectedOp, dispatch]);

  useEffect(() => {
    const options = async () => {
      try {
        const data = await dispatch(getProducts()).unwrap();
        setOptions(data);
      } catch (error) {}
    };

    options();
  }, [dispatch]);

  const handleStep = e => {
    nextStep();
  };

  const handleDetail = e => {
    e = {
      ...e,
      productId: selectedOp,
    };
    detail(e);
  };

  const handleQuoteClose = e => {
    setOpenQuote(false);
  };

  const handleQuote = async e => {
    const data = await dispatch(getTransactionsByBatch(e.batchFileId)).unwrap();
    data.map(x =>
      x.timestamp !== ''
        ? (x.timestamp = moment(x.timestamp).format('DD/MM/YYYY'))
        : (x.timestamp = '----')
    );
    setTransactions(data);
    setSelectedBatch(e);
    setOpenQuote(true);
  };

  const handleConfirm = async e => {
    setLoading(true);
    if (selectedBatch.status !== 'QUOTED') {
      await dispatch(quoteBatch(selectedBatch.batchFileId)).unwrap();
      const data = await dispatch(
        getTransactionsByBatch(selectedBatch.batchFileId)
      ).unwrap();
      data.map(x =>
        x.timestamp !== ''
          ? (x.timestamp = moment(x.timestamp).format('DD/MM/YYYY'))
          : (x.timestamp = '----')
      );
      setTransactions(data);
      toast({
        title: t('toast.batchQuoted'),
        position: 'top',
        description: t('toast.batchQuotedDesc'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      setQuote(true);
    } else {
      await dispatch(confirmBatch(selectedBatch.batchFileId)).unwrap();
      toast({
        title: t('toast.batchSend'),
        position: 'top',
        description: t('toast.batchSendDesc'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      setQuote(false);
      setOpenQuote(false);
    }
    const data = await dispatch(getBatches(selectedOp)).unwrap();
    data?.queue.data.map(
      x => (x.timestamp = moment(x.timestamp).format('DD/MM/YYYY'))
    );
    if (data.processed.data.lenght > 0) {
      data?.processed.map(
        x => (x.timestamp = moment(x.timestamp).format('DD/MM/YYYY'))
      );
      setProcessedBatches(data.processed.data);
    }
    setBatchesQueue(data.queue.data);
    setLoading(false);
  };

  const handleSetOp = e => {
    if (e.target.value !== '') {
      setSelectedOp(e.target.value);
    }
  };

  return (
    <>
      <Flex justifyContent="space-between" alignItems="end" display={'flex'}>
        <Box>
          <Text>{t('octBatch.chooseProd')}</Text>
          <Select onChange={handleSetOp} placeholder="Choose">
            {options
              ? options.map(option => {
                  return (
                    <option value={option.id}>{option.description} </option>
                  );
                })
              : ''}
          </Select>
        </Box>

        <Button
          cursor="pointer"
          bgColor={'white'}
          color={colors.primary}
          border={'1px solid #982549'}
          fontSize={15}
          mt={3}
          type="submit"
          onClick={handleStep}
          boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
        >
          {t('octBatch.uploadFile')}
        </Button>
      </Flex>
      <Divider my={5} />

      <Flex
        my={3}
        direction={'row'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        width={'100%'}
      >
        {balance?.map(m => {
          const formattedAmount = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: m.currency,
          }).format(parseFloat(m.amount));
          return (
            <Box
              bgColor={'#982549'}
              border={'2px solid'}
              borderColor={'white'}
              borderRadius={10}
              mt={3}
              boxShadow="lg"
              minW={'8vw'}
              justifyContent={'center'}
              display={'flex'}
            >
              <Text color={'white'} as={'b'} p={5}>
                {formattedAmount}
              </Text>
            </Box>
          );
        })}
      </Flex>

      <Flex
        justifyContent="center"
        display={'flex'}
        direction="column"
        textAlign="start"
      >
        <Box my={8} width={'90vw'}>
          <Text as={'u'} fontSize={'2xl'}>
            {t('octBatch.batchesQueue')}
          </Text>
          <DataTable
            value={batchesQueue}
            paginator
            rows={10}
            emptyMessage="Nenhum registro encontrado"
          >
            {columns.map(col => (
              <Column
                key={col.field}
                field={col.field}
                header={t(`octBatch.${col.header}`)}
                width={col.width}
                sortable={col.sortable}
                body={col.body}
              />
            ))}
          </DataTable>
        </Box>

        <Box my={8} width={'90vw'}>
          <Text as={'u'} fontSize={'2xl'}>
            {t('octBatch.batchesProcessed')}
          </Text>
          <DataTable
            value={processedBatches}
            paginator
            rows={10}
            emptyMessage="Nenhum registro encontrado"
          >
            {columnsProcessed.map(col => (
              <Column
                key={col.field}
                field={col.field}
                header={t(`octBatch.${col.header}`)}
                sortable={col.sortable}
                body={col.body}
              />
            ))}
          </DataTable>
        </Box>
      </Flex>

      <Modal
        size="4xl"
        isCentered
        isOpen={openQuote}
        onClose={handleQuoteClose}
      >
        <ModalOverlay />
        <ModalContent minH={'50vh'} minW={'70vw'} background={'white'}>
          <ModalHeader>{t('octBatch.transactionsBatch')}</ModalHeader>

          <Box mx={3} alignContent={'center'} justifyContent={'center'}>
            <DataTable
              p={10}
              value={transactions}
              paginator
              rows={10}
              emptyMessage="Nenhum registro encontrado"
            >
              {columnsTransactions.map(col => (
                <Column
                  key={col.field}
                  field={col.field}
                  header={col.header}
                  width={col.width}
                  sortable={col.sortable}
                  body={col.body}
                />
              ))}
            </DataTable>
          </Box>
          <ModalFooter>
            <Button
              border={'1px solid'}
              borderColor={'#7e7477'}
              onClick={handleQuoteClose}
              mx={2}
              variant="ghost"
            >
              {t('buttons.cancel')}
            </Button>
            {selectedBatch.status !== 'PROCESSED' && (
              <Button
                colorScheme="blue"
                mr={3}
                isLoading={loading}
                onClick={handleConfirm}
              >
                {t('buttons.confirm')}{' '}
                {selectedBatch.status === 'QUOTED' || quote
                  ? t('labels.transaction')
                  : t('labels.quote')}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
