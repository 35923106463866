import { createAsyncThunk } from '@reduxjs/toolkit';
import { getToken } from '../../components/auth/UserData';
import baseUrl from '../../config/config';
import axios from 'axios';

export const getProducts = createAsyncThunk('products/get', async () => {
  const response = await axios.get(
    `${baseUrl}/portalapi/notification/webhooks`, {
      headers: {
        Authorization: `${getToken()}`,
      },
    });

    console.log("Resoponse ::: ",response.data);
  return response.data;
});

export const getProduct = createAsyncThunk(
  'products/getId',
  async product_code => {
    const response = await axios.get(
      `${baseUrl}/portalapi/notification/webhooks/${product_code}`, {
        headers: {
          Authorization: `${getToken()}`,
        },
      });
    return response.data;
  }
);

export const registerWebhook = createAsyncThunk(
  'webhook/register',
  async data => {
    data = {
      productCode: data.productCode,
      paymentUrl: data.paymentUrl,
      octUrl: data.octUrl,
    };

    const response = await axios.post(`${baseUrl}/portalapi/notification/webhooks`, data, {
        headers: {
          Authorization: `${getToken()}`,
        },
    });
    return response.data;
  }
);

export const deleteWebhook = createAsyncThunk('webhook/delete', async id => {
  try {
    const response = await axios.delete(
      `${baseUrl}/portalapi/notification/webhooks/${id}`, {
        headers: {
          Authorization: `${getToken()}`,
        },
      });
    return response.data;
  } catch (error) {}
});
