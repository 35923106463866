import { createAsyncThunk } from '@reduxjs/toolkit';
import { getToken, getUsername } from '../../components/auth/UserData';
import axios from 'axios';
import baseUrl from '../../config/config';

export const getProducts = createAsyncThunk('products/get', async () => {
  const response = await axios.get(`${baseUrl}/portalapi/product`, {
    headers: {
      Authorization: `${getToken()}`,
    },
  });
  return response.data;
});

export const getProduct = createAsyncThunk('products/getId', async id => {
  const response = await axios.get(`${baseUrl}/portalapi/product/${id}`, {
    headers: {
      Authorization: `${getToken()}`,
    },
  });
  return response.data;
});

export const getProvider = createAsyncThunk('products/provider', async id => {
  const response = await axios.get(`${baseUrl}/portalapi/provider`, {
    headers: {
      Authorization: `${getToken()}`,
    },
  });
  return response.data;
});

export const registerProduct = createAsyncThunk(
  'products/register',
  async data => {
    try {
      data.settlementCurrencyCode = data.settlementCurrencyCode.map(
        item => item.name
      );
      data = {
        productCode: data.productCode,
        description: data.description,
        providerId: data.provider,
        acquirerId: getUsername(),
        providerPartnerId: data.partnerId,
        fees: data.fees,
        limits: data.limits,
        settlementCurrencies: data.settlementCurrencyCode,
        destinationCountry: data.countryCode,
      };
      const response = await axios.post(`${baseUrl}/portalapi/product`, data, {
        headers: {
          Authorization: `${getToken()}`,
        },
      });
      return response.data;
    } catch (error) {}
  }
);
