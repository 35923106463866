import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Divider,
  Link,
  Image,
  Icon,
} from '@chakra-ui/react';
import {
  FiUsers,
  FiBox,
  FiLink,
  FiLogIn,
  FiShoppingCart,
} from 'react-icons/fi';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  HamburgerIcon,
} from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { getProfle, removeUserSession } from '../../components/auth/UserData';
import { useTranslation } from 'react-i18next';

const LinkItemsMuevy = [
  { title: 'Muevy', name: 'acquirer', href: '/muevy/acquirers', icon: FiBox },
  { name: 'user', href: '/muevy/users', icon: FiUsers },
];

const LinkItemsAcquirer = [
  {
    title: 'Acquirer',
    name: 'merchants',
    href: '/acquirer/merchants',
    icon: FiLink,
  },
  {
    name: 'oct',
    icon: FiLogIn,
    childrens: [
      { name: 'sendBatchOCT', href: '/acquirer/oct' },
      { name: 'sendUnitaryOCT', href: '/acquirer/octunitary' },
    ],
  },
  {
    name: 'product',
    icon: FiShoppingCart,
    childrens: [
      { name: 'subProduct', href: '/acquirer/products' },
      { name: 'webhooks', href: '/acquirer/product/webhook' },
    ],
  },

  { name: 'user', href: '/muevy/users', icon: FiUsers },
];

const LinkItemsMerchant = [
  { title: 'Merchant', name: 'User', href: '/muevy/users', icon: FiUsers },
];

const Nav = ({ children, onClick, isOpen, hasChildren, ...rest }) => {
  return (
    <Flex
      align="start"
      ml="2"
      my={3}
      borderRadius="lg"
      role="group"
      cursor="pointer"
      onClick={() => (isOpen = false)}
      {...rest}
    >
      <Text p="2" mr="3" fontSize={15} color="black">
        {children}
      </Text>
      {hasChildren && (
        <Icon
          mt={3}
          color={'black'}
          as={isOpen ? ChevronDownIcon : ChevronRightIcon}
          variant="unstyled"
          ml="auto"
          aria-label="Expand menu"
        />
      )}
    </Flex>
  );
};

const NavSub = ({ children, ...rest }) => {
  return (
    <Flex
      align="center"
      p="2"
      mx="4"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      {...rest}
    >
      <Text as="u" color="black" fontSize={13}>
        {children}
      </Text>
    </Flex>
  );
};

const SidebarContent = ({ onClose, linkItems, ...rest }) => {
  const navigate = useNavigate();
  const [openIndexes, setOpenIndexes] = useState([]);
  const { t, i18n } = useTranslation();
  const handleToggle = index => {
    setOpenIndexes(prevOpenIndexes =>
      prevOpenIndexes.includes(index)
        ? prevOpenIndexes.filter(i => i !== index)
        : [...prevOpenIndexes, index]
    );
  };
  const handleNavigate = e => {
    navigate(e);
    onClose();
  };

  return (
    <Box
      transition="3s ease"
      bg={'white'}
      borderRightColor={'white'}
      {...rest}
      p={3}
      justifyContent={'center'}
      alignContent={'center'}
    >
      {linkItems.map((link, index) => (
        <>
          <Text m={2} fontSize="lg">
            {link.title}
          </Text>
          <Box
            key={index}
            onClick={() => handleToggle(index)}
            isOpen={openIndexes.includes(index)}
            _hover={{
              bgColor: '#edf0f5',
              borderRadius: 10,
            }}
          >
            <Nav hasChildren={!!link.childrens}>
              <Flex
                direction="row"
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Icon
                  color={'black'}
                  as={link.icon}
                  variant="unstyled"
                  boxSize={5}
                  mr={3}
                />
                <Text
                  fontSize="md"
                  cursor={'underline'}
                  onClick={() => handleNavigate(link.href, false)}
                >
                  {t(`sidebar.${link.name}`)}
                </Text>
              </Flex>
            </Nav>
            {openIndexes.includes(index) && link.childrens && (
              <Flex direction="column" ml={8}>
                {link.childrens.map((child, i) => (
                  <NavSub key={i}>
                    <Text
                      onClick={() => handleNavigate(child.href, false)}
                      fontSize="md"
                    >
                      {t(`sidebar.${child.name}`)}
                    </Text>
                  </NavSub>
                ))}
              </Flex>
            )}
          </Box>
        </>
      ))}

      <Box p="1">
        <Divider borderColor={'black'} mt="4" mb="2" />
        <Text fontSize="sm" color="gray.500">
          version 0.0.1
        </Text>
      </Box>
    </Box>
  );
};

const SideBar = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [linkItems, setLinkItems] = React.useState([]);

  useEffect(() => {
    if (getProfle() === 'ADMIN') {
      setLinkItems(LinkItemsMuevy);
    } else if (getProfle() === 'ACQUIRER') {
      setLinkItems(LinkItemsAcquirer);
    }
  }, []);

  const handleNavigate = e => {
    navigate(e);
    onClose();
  };
  return (
    <>
      <Flex
        direction={'column'}
        alignItems={'center'}
        justifyContent="space-between"
        p={3}
        maxH="100vh"
      >
        <HamburgerIcon
          boxSize={6}
          color={'#982549'}
          onClick={onOpen}
          cursor={'pointer'}
          m={3}
        ></HamburgerIcon>

        {linkItems.map((link, index) => (
          <Box key={index} my={6} justifyContent={'center'}>
            <Icon
              key={index}
              onClick={() => handleNavigate(link.href)}
              color={'black'}
              as={link.icon}
              variant="unstyled"
              ml="auto"
              boxSize={4}
              cursor={'pointer'}
              _hover={{
                transform: 'scale(1.2)',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                borderRadius: 'full',
              }}
              _focus={{
                transform: 'scale(1.2)',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                borderRadius: 'full',
              }}
            ></Icon>
          </Box>
        ))}
      </Flex>
      <Drawer
        size={'xs'}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
      >
        <DrawerContent overflowY="auto" scrollBehavior="inside">
          <Flex
            alignItems="center"
            justifyContent="center"
            direction={'column'}
            mt={5}
          >
            <Image
              borderRadius={'60%'}
              p={2}
              border={'2px solid #982549'}
              cursor={'pointer'}
              onClick={() => navigate('/')}
              src="/img/m_logo.svg"
              style={{ width: '40px', height: '40px' }}
            />
          </Flex>
          <SidebarContent onClose={onClose} linkItems={linkItems} />
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SideBar;
