import { createAsyncThunk } from '@reduxjs/toolkit';
import { getToken } from '../../components/auth/UserData';
import axios from 'axios';
import baseUrl from '../../config/config';

export const getAcquirers = createAsyncThunk('acquirers/get', async () => {
  const response = await axios.get(`${baseUrl}/portalapi/acquirer`, {
    headers: {
      Authorization: `${getToken()}`,
    },
  });
  return response.data;
});

export const getAcquirer = createAsyncThunk('acquirer/get', async id => {
  const response = await axios.get(`${baseUrl}/portalapi/acquirer/${id}`, {
    headers: {
      Authorization: `${getToken()}`,
    },
  });
  return response.data;
});

export const registerAcquirer = createAsyncThunk(
  'acquirer/register',
  async data => {
    data = {
      businessName: data.businessName,
      businessId: data.businessId,
      contactName: data.contactName,
      contactPhone: data.contactPhone,
      contactEmail: data.contactEmail,
      paymentOriginationCountry: data.paymentOriginationCountry,
      addresses: [
        {
          type: 'MAIN',
          street: data.street,
          number: data.number,
          complement: data.complement,
          zipCode: data.zipCode,
          city: data.city,
          state: data.state,
          country: data.country,  
        },
      ],
    };
    const response = await axios.post(`${baseUrl}/portalapi/acquirer`, data, {
      headers: {
        Authorization: `${getToken()}`,
      },
    });
    return response.data;
  }
);

export const deleteAcquirer = createAsyncThunk('acquirer/delete', async id => {
  try {
    const response = await axios.delete(`${baseUrl}/portalapi/acquirer/${id}`, {
      headers: {
        Authorization: `${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {}
});
