import React, { useEffect, useState, useRef } from 'react';
import {
  Flex,
  Box,
  Button,
  ButtonGroup,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Stack,
  StackDivider,
  Spacer,
  Text,
  Select,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerFooter,
  DrawerOverlay,
  VStack,
  Skeleton,
} from '@chakra-ui/react';
import { SearchIcon, RepeatIcon } from '@chakra-ui/icons';
import { MdOutlineSave, MdOutlineFilterAlt } from 'react-icons/md';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { useDispatch } from 'react-redux';
import {
  getTransactions,
  getTotalTransactions,
} from '../../redux/action/dashboard';

import { useTranslation } from 'react-i18next';
import colors from '../../assets/custom/colors';
import moment from 'moment';

const formatDate = dateString => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const DataProcess = ({ totalTransactionsCallback }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [filters, setFilters] = useState({
    product_id: '',
    list_status_transaction: '',
    start_date: '',
    end_date: '',
  });
  
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstFieldRef = useRef(null);
  const list_status = ['SUCCESS', 'REJECTED', 'PENDING'];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const fetchDataTransaction = async () => {
      try {
        
        const today = moment().format("YYYY-MM-DD");
        const firstDate = moment().subtract(60, 'days').format("YYYY-MM-DD");

        const transactionData = await dispatch(
          getTransactions({ dateFrom: firstDate, dateTo: today })
        ).unwrap();
        const totalTransactions = await dispatch(
          getTotalTransactions({ dateFrom: firstDate, dateTo: today })
        ).unwrap();
        
        setTransactions(transactionData);
        totalTransactionsCallback(totalTransactions);
        setLoading(false);

      } catch (error) {
        console.error(error);
      }
    };
    fetchDataTransaction();
  }, [dispatch]);

  const handleSearch = event => setSearchTerm(event.target.value);
  const handleFilterSearch = async () => {
    try {
      

      setLoading(true);
      const { start_date, end_date } = filters;
      const transactionsData = await dispatch(
        getTransactions({
          dateFrom: moment(start_date).format("YYYY-MM-DD"),
          dateTo: moment(end_date).format("YYYY-MM-DD"),
        })
      ).unwrap();
      const totalTransactionsData = await dispatch(
        getTotalTransactions({
          dateFrom: formatDate(start_date),
          dateTo: formatDate(end_date),
        })
      ).unwrap();
      setTransactions(transactionsData);
      totalTransactionsCallback(totalTransactionsData);
      
      setLoading(false);

    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenDetails = transaction => {
    setSelectedTransaction(transaction);
    setShowDetail(true);
  };

  const handleCloseDetails = () => setShowDetail(false);

  const clearFields = () => {
    setFilters('');
  };
  const refreshDataTable = async () => {
    try {
      const today     = moment().format("YYYY-MM-DD");
      const firstDate = moment().subtract(60, 'days').format("YYYY-MM-DD");
      const transactionData = await dispatch(
        getTransactions({ dateFrom: firstDate, dateTo: today })
      ).unwrap();
      const totalTransactions = await dispatch(
        getTotalTransactions({ dateFrom: firstDate, dateTo: today })
      ).unwrap();

      setTransactions(transactionData);
      totalTransactionsCallback(totalTransactions);
      
      setLoading(false);

    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    { field: 'timestamp', header: 'transmissionDate' },
    { field: 'productId', header: 'productId' },
    // { field: 'currencyCode', header: 'sourceCurrency' },
    // { field: 'amountRecipient', header: 'sourceAmount' },
    { field: 'currencyCodeRecipient', header: 'destCurrency' },
    { field: 'amount', header: 'destAmount' },
    { field: 'status', header: 'status' },
    {
      field: 'iconView',
      header: 'iconView',
      body: rowData => (
        <SearchIcon
          marginLeft={4}
          cursor={'pointer'}
          onClick={() => handleOpenDetails(rowData)}
        />
      ),
    },
  ];

  return (
    <>
      <Flex direction="row" alignItems="center" textAlign="center" py={3}>
        <Box>
          <Popover
            isOpen={isOpen}
            initialFocusRef={firstFieldRef}
            onOpen={onOpen}
            onClose={onClose}
            closeOnBlur={false}
            placement="right"
          >
            <PopoverTrigger>
              <Button border={'1px solid'} mx={1} variant="outline">
                <MdOutlineFilterAlt />
              </Button>
            </PopoverTrigger>
            <PopoverContent ml={2}>
              <PopoverArrow />
              <PopoverCloseButton />
              <Stack spacing={1} p={4}>
                <FormLabel>{t(`dashboard.status`)}</FormLabel>
                <Select
                  id="status"
                  value={filters.list_status_transaction}
                  onChange={e =>
                    setFilters({ ...filters, list_status_transaction: e.value })
                  }
                  placeholder={t(`dashboard.selectStatus`)}
                >
                  {list_status.map(status => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </Select>

                <FormLabel mt={9}>{t(`dashboard.startDate`)}</FormLabel>
                <div className="">
                  <Calendar
                    className="custom-calendar"
                    name="start_date"
                    value={filters.start_date || ''}
                    onChange={e =>
                      setFilters({ ...filters, start_date: e.value })
                    }
                    showIcon
                    dateFormat="dd/mm/yy"
                  />
                </div>

                <FormLabel mt={9}>{t(`dashboard.endDate`)}</FormLabel>
                <Calendar
                  name="end_date"
                  value={filters.end_date || ''}
                  onChange={e => setFilters({ ...filters, end_date: e.value })}
                  showIcon
                  dateFormat="dd/mm/yy"
                />

                <ButtonGroup mt={9} display="flex" justifyContent="flex-end">
                  <Button
                    variant="outline"
                    onClick={e => {
                      e.preventDefault();
                      clearFields();
                    }}
                    color="teal.500"
                    mr={0}
                  >
                    {t(`dashboard.clear`)}
                  </Button>
                  <Button variant="outline" onClick={onClose}>
                  {t(`dashboard.cancel`)}
                  </Button>
                  <Button colorScheme="teal" onClick={handleFilterSearch}>
                  {t(`dashboard.search`)}
                  </Button>
                </ButtonGroup>
              </Stack>
            </PopoverContent>
          </Popover>

          <Button
            border={'1px solid'}
            mx={1}
            onClick={() => refreshDataTable()}
            variant="outline"
          >
            <RepeatIcon />
          </Button>

          <Button
            mx={1}
            border={'1px solid'}
            onClick={() => setSearchTerm('')}
            variant="outline"
          >
            <MdOutlineSave />
          </Button>
        </Box>
        <Spacer />
        <Box>
          <InputGroup my={3} width={'20vw'}>
            <Input
              borderColor={'black'}
              placeholder={t(`dashboard.search`)}
              value={searchTerm}
              onChange={handleSearch}
            />
            <InputRightElement>
              <SearchIcon color={'black'} />
            </InputRightElement>
          </InputGroup>
        </Box>
      </Flex>
      {loading ? ( 
        <Stack>
          <Skeleton height='20px' />
          <Skeleton height='20px' />
        </Stack>

      ): (
      <DataTable
        value={transactions}
        paginator
        rows={10}
        globalFilter={searchTerm}
        emptyMessage={t(`dashboard.noRecordsFound`)}
      >
        {columns.map(col => (
          <Column
            key={col.field}
            field={col.field}
            header={t(`dashboard.${col.header}`)}
            sortable
            body={col.body}
          />
        ))}
      </DataTable>
      )}

      <Drawer
        isOpen={showDetail}
        size="xl"
        placement="right"
        onClose={handleCloseDetails}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{t(`dashboard.details`)}</DrawerHeader>
          <DrawerBody>
            <DrawerHeader>{t(`dashboard.sender`)}</DrawerHeader>
            <VStack
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
              align="stretch"
            >
              <Box>
                <Text>{t(`dashboard.name`)}</Text>
                <Input
                  isDisabled
                  value={selectedTransaction?.nameSender || ''}
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.addressCity`)}</Text>
                <Input
                  value={selectedTransaction?.addressCitySender || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.addressCountry`)}</Text>
                <Input
                  value={selectedTransaction?.addressCountrySender || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>
              <Box>
                <Text>{t(`dashboard.addressLine`)}</Text>
                <Input
                  value={selectedTransaction?.addressLineSender || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>
              <Box>
                <Text>{t(`dashboard.senderAccountNumber`)}</Text>
                <Input
                  value={selectedTransaction?.senderAccountNumberSender || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>
              <Box>
                <Text>{t(`dashboard.taxId`)}</Text>
                <Input
                  value={selectedTransaction?.taxIdSender || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>
              <Box>
                <Text>{t(`dashboard.type`)}</Text>
                <Input
                  value={selectedTransaction?.typeSender || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>
            </VStack>

            <DrawerHeader>{t(`dashboard.recipient`)}</DrawerHeader>
            <VStack
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
              align="stretch"
            >
              <Box>
                <Text>{t(`dashboard.taxId`)}</Text>
                <Input
                  isDisabled
                  value={selectedTransaction?.taxIdRecipient || ''}
                  variant="filled"
                />
              </Box>
              <Box>
                <Text>{t(`dashboard.firstName`)}</Text>
                <Input
                  value={selectedTransaction?.firstNameRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>
              <Box>
                <Text>{t(`dashboard.lastName`)}</Text>
                <Input
                  value={selectedTransaction?.lastNameRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>
              <Box>
                <Text>{t(`dashboard.bankCode`)}</Text>
                <Input
                  value={selectedTransaction?.bankCodeRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>
              <Box>
                <Text>{t(`dashboard.bankCodeType`)}</Text>
                <Input
                  value={selectedTransaction?.bankCodeTypeRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>
              <Box>
                <Text>{t(`dashboard.accountNumberType`)}</Text>
                <Input
                  value={selectedTransaction?.accountNumberTypeRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>
              <Box>
                <Text>{t(`dashboard.accountNumber`)}</Text>
                <Input
                  value={selectedTransaction?.accountNumberRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.accountName`)}</Text>
                <Input
                  value={selectedTransaction?.accountNameRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.countryCode`)}</Text>
                <Input
                  value={selectedTransaction?.countryCodeRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.currencyCode`)}</Text>
                <Input
                  value={selectedTransaction?.currencyCodeRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.amount`)}</Text>
                <Input
                  value={selectedTransaction?.amountRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.addressCountry`)}</Text>
                <Input
                  value={selectedTransaction?.addressCountryRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.addressState`)}</Text>
                <Input
                  value={selectedTransaction?.addressStateRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.addressCity`)}</Text>
                <Input
                  value={selectedTransaction?.addressCityRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.addressPostalCode`)}</Text>
                <Input
                  value={selectedTransaction?.addressPostalCodeRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.addressLine`)}</Text>
                <Input
                  value={selectedTransaction?.addressLineRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>
            </VStack>
          </DrawerBody>
          <DrawerFooter>
            <Button
              bgColor={'white'}
              color={colors.primary}
              border={'1px solid #982549'}
              onClick={handleCloseDetails}
              m={3}
              fontSize={15}
              boxShadow={'4px 4px 4px rgba(0, 0, 0, 0.1)'}
            >
              {t(`buttons.cancel`)}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DataProcess;
