import { createAsyncThunk } from '@reduxjs/toolkit';
import { getToken } from '../../components/auth/UserData';
import axios from 'axios';
import baseUrl from '../../config/config';

export const getTransactions = createAsyncThunk(
  'transactions/get',
  async () => {
    const response = await axios.get(
      `${baseUrl}/portalapi/oct/product/eedcc851-0bec-47ae-b3c5-31544b5db2e8`,
      {
        headers: {
          Authorization: `${getToken()}`,
        },
      }
    );
    return response.data;
  }
);

export const getTransactionById = createAsyncThunk(
  'transactions/get',
  async e => {
    const response = await axios.get(
      `${baseUrl}/portalapi/octbatch/${e.batchId}/transaction/${e.transactionId}`,
      {
        headers: {
          Authorization: `${getToken()}`,
        },
      }
    );
    return response.data;
  }
);

export const getBatches = createAsyncThunk('batchesQueue/get', async e => {
  const batchesQueue = await axios.get(
    `${baseUrl}/portalapi/octbatch/waiting/product/${e}`,
    {
      headers: {
        Authorization: `${getToken()}`,
      },
    }
  );

  const batchesProcessed = await axios.get(
    `${baseUrl}/portalapi/octbatch/processed/product/${e}`,
    {
      headers: {
        Authorization: `${getToken()}`,
      },
    }
  );

  return { queue: batchesQueue, processed: batchesProcessed };
});

export const getTransactionsByBatch = createAsyncThunk(
  'transactionsByBatch/get',
  async e => {
    const response = await axios.get(
      `${baseUrl}/portalapi/octbatch/${e}/transaction`,
      {
        headers: {
          Authorization: `${getToken()}`,
        },
      }
    );
    return response.data;
  }
);

export const registerBatchOct = createAsyncThunk(
  'oct/registerBatch',
  async e => {
    try {
      const data = new FormData();
      data.append('productId', e.product.id);
      data.append('file', e.file);
      const response = await axios.post(
        `${baseUrl}/portalapi/octbatch/upload`,
        data,
        {
          headers: {
            Authorization: `${getToken()}`,
          },
        }
      );
      return response;
    } catch (error) {}
  }
);

export const registerOct = createAsyncThunk('oct/register', async e => {
  let data = {
    quoteId: e.quoteId,
    otpToken: e.otpToken,
    productId: e.productId,
    amount: e.amount,
    paymentType: e.paymentType,
    sender: {
      name: e.senderFirstName,
      senderAccountNumber: 'ewallet:474709;sp=ibcorretora',
      addressCountry: 'BRA',
      addressLine: e.senderAddressLine,
    },
    recipient: {
      firstName: e.recipientFirstName,
      lastName: e.recipientLastName,
      bankCode: e.recipientBankCode,
      accountNumberType: 'DEFAULT',
      accountNumber: e.recipientAccountNumber,
      currencyCode: e.recipientCurrencyCode,
      countryCode: e.recipientCountryCode,
      addressCountry: e.recipientCountry,
      addressState: e.recipientState,
      addressCity: e.recipientCity,
      addressPostalCode: e.recipientPostalCode,
      addressLine: e.recipientAddressLine,
    },
  };
  try {
    const response = await axios.post(`${baseUrl}/portalapi/oct`, data, {
      headers: {
        Authorization: `${getToken()}`,
      },
    });
    return response;
  } catch (error) {}
});

export const quoteBatch = createAsyncThunk('oct/quoteBatch', async e => {
  try {
    const response = await axios.post(
      `${baseUrl}/portalapi/octbatch/${e}/quote`,
      [],
      {
        headers: {
          Authorization: `${getToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {}
});

export const quoteUnitary = createAsyncThunk('oct/quoteUnitary', async e => {
  try {
    const response = await axios.post(`${baseUrl}/portalapi/quote`, e, {
      headers: {
        Authorization: `${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {}
});

export const confirmBatch = createAsyncThunk('oct/quoteBatch', async e => {
  try {
    const response = await axios.post(
      `${baseUrl}/portalapi/octbatch/${e}/oct`,
      [],
      {
        headers: {
          Authorization: `${getToken()}`,
        },
      }
    );
    return response;
  } catch (error) {}
});

export const getBalanceByProd = createAsyncThunk('oct/balance', async e => {
  try {
    const response = await axios.get(
      `${baseUrl}/portalapi/balance/product/${e}`,
      {
        headers: {
          Authorization: `${getToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {}
});
