import { createAsyncThunk } from '@reduxjs/toolkit';
import { getToken } from '../../components/auth/UserData';
import axios from 'axios';
import baseUrl from '../../config/config';

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const response = await axios.get(
    `${baseUrl}/portalapi/portaluser/usercredentials`,
    {
      headers: {
        Authorization: `${getToken()}`,
      },
    }
  );
  return response.data;
});

export const registerUser = createAsyncThunk('users/register', async data => {
  try {
    const response = await axios.post(
      `${baseUrl}/portalapi/portaluser/usercredentials`,
      data,
      {
        headers: {
          Authorization: `${getToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {}
});

export const deleteUser = createAsyncThunk('users/delete', async id => {
  try {
    const response = await axios.delete(
      `${baseUrl}/portalapi/portaluser/usercredentials/${id}`,
      {
        headers: {
          Authorization: `${getToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {}
});

export const getUser = createAsyncThunk('users/get', async id => {
  try {
    const response = await axios.get(
      `${baseUrl}/portalapi/portaluser/usercredentials/${id}`,
      {
        headers: {
          Authorization: `${getToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {}
});

export const forgotPassword = createAsyncThunk('users/register', async data => {
  try {
    const response = await axios.post(
      `${baseUrl}/portalapi/portaluser/forgot`,
      data
    );

    return response;
  } catch (error) {}
});

export const resetPassword = createAsyncThunk('users/register', async data => {
  try {
    const response = await axios.post(
      `${baseUrl}/portalapi/portaluser/reset`,
      data
    );
    return response;
  } catch (error) {}
});

export const createPassword = createAsyncThunk(
  'users/createNew',
  async data => {
    try {
      const response = await axios.post(
        `${baseUrl}/portalapi/portaluser/create`,
        data
      );
      return response;
    } catch (error) {}
  }
);
