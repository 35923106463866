import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Flex,
  Text,
  Input,
  Button,
  TableContainer,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Spacer,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  StackDivider,
  Drawer,
  DrawerContent,
  useDisclosure,
  DrawerHeader,
  DrawerOverlay,
  DrawerBody,
  DrawerCloseButton,
  DrawerFooter,
  Divider,
} from '@chakra-ui/react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch } from 'react-redux';
import {
  fetchUsers,
  deleteUser,
  registerUser,
  getUser,
} from '../../../redux/action/users';
import { getAcquirers } from '../../../redux/action/acquirer';
import { useToast } from '@chakra-ui/react';
import { DeleteIcon, SearchIcon } from '@chakra-ui/icons';
import { getProfle } from '../../../components/auth/UserData';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const DataProcess = () => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      acquirer: '',
      description: '',
      email: '',
    },
  });
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [acquirers, setAcquirers] = useState('');
  const [selectedAcquirer, setSelectedAcquirer] = useState('');

  useEffect(() => {
    const acquirers = async () => {
      try {
        let data = await dispatch(getAcquirers()).unwrap();
        setAcquirers(data);
      } catch (error) {}
    };
    acquirers();
  }, []);

  const handleChangeAcquirer = e => {
    let acq = acquirers.find(acquirer => acquirer.id === e.target.value);
    setSelectedAcquirer(acq);
  };

  const deleteButtonTemplate = (rowData, onOpenModal) => {
    return (
      <DeleteIcon
        marginLeft={4}
        cursor="pointer"
        onClick={() => onOpenModal(rowData)}
      />
    );
  };
  const ViewButtonTemplate = rowData => {
    return (
      <SearchIcon
        marginLeft={4}
        cursor={'pointer'}
        onClick={() => handleView(rowData)}
      />
    );
  };

  useEffect(() => {
    const users = async () => {
      try {
        let data = await dispatch(fetchUsers()).unwrap();
        if (getProfle() !== 'ADMIN') {
          data = data.filter(x => x.profile === getProfle());
        }
        setUsers(data);
        onClose();
      } catch (error) {}
    };

    users();
  }, []);

  const columns = [
    { field: 'profile', header: 'Perfil', sortable: true },
    { field: 'description', header: 'Name', sortable: true },
    { field: 'email', header: 'E-mail', sortable: true },
    // { field: 'profile', header: 'Profile', sortable: true },
    // { field: 'active', header: 'Active', sortable: true },
    { field: 'date', header: 'Created At', sortable: true },
    {
      field: 'iconDelete',
      color: 'black',
      header: 'Delete',
      sortable: false,
      body: rowData => deleteButtonTemplate(rowData, handleOpenModal),
    },

    {
      field: 'iconView',
      color: 'black',
      header: 'Details',
      sortable: false,
      body: ViewButtonTemplate,
    },
  ];

  async function onSubmit(values) {
    dispatch(
      registerUser({
        profile: 'ACQUIRER',
        description: values.description,
        email: values.email,
        merchantId: 'MERCHANT_ID',
        acquirerId: selectedAcquirer.id ? selectedAcquirer.id : '',
        secret: '123456',
      })
    );
    toast({
      title: t('toast.userCreateTitle'),
      position: 'top',
      description: t('toast.userCreateDesc'),
      status: 'success',
      duration: 4000,
      isClosable: true,
    });
    onClose();
    setTimeout(async () => {
      let data = await dispatch(fetchUsers()).unwrap();
      if (getProfle() !== 'ADMIN') {
        data = data.filter(x => x.profile === getProfle());
      }
      setUsers(data);
    }, 2000);
  }

  const handleOpenModal = user => {
    setSelectedUser(user);
    setIsDelModalOpen(true);
  };

  const handleOnOpen = e => {
    reset({
      description: '',
      email: '',
    });
    onOpen();
  };

  const handleDelete = async data => {
    await dispatch(deleteUser(selectedUser.id)).unwrap();
    await dispatch(fetchUsers()).unwrap();
    toast({
      title: t('toast.userDeleteTitle'),
      position: 'top',
      description: t('toast.userDeleteDesc'),
      status: 'success',
      duration: 4000,
      isClosable: true,
    });
    setIsDelModalOpen(false);
    let user = await dispatch(fetchUsers()).unwrap();
    if (getProfle() !== 'ADMIN') {
      user = user.filter(x => x.profile === getProfle());
    }
    setUsers(user);
  };

  const handleView = async data => {
    let user = await dispatch(getUser(data.id)).unwrap();
    setSelectedUser(user);
    setIsViewModalOpen(true);
  };

  const handleClose = async data => {
    let user = await dispatch(fetchUsers()).unwrap();
    if (getProfle() !== 'ADMIN') {
      user = user.filter(x => x.profile === getProfle());
    }
    setUsers(user);
    setChangePassword(false);
    setIsViewModalOpen(false);
  };

  const handleModalClose = async () => {};

  // const handlePassword = e => {
  //   setChangePassword(e);
  // };

  return (
    <>
      <Flex direction="column" alignItems="start" textAlign="center">
        <Button
          my={2}
          onClick={handleOnOpen}
          variant="outline"
          colorScheme={'#982549'}
        >
          {t('users.addNew')} +
        </Button>

        <Divider my={8} borderColor={'black'} />

        <Drawer
          placement="right"
          isOpen={isOpen}
          onClose={handleModalClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
        >
          <DrawerContent overflowY="auto" backgroundColor={'white'}>
            <DrawerHeader color={'black'} borderBottomWidth="1px">
              {t('users.createNew')}
            </DrawerHeader>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box m={3}>
                <Grid templateColumns="repeat(1, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.acquirer}>
                    <FormLabel>Acquirer:</FormLabel>
                    <Select
                      onChange={handleChangeAcquirer}
                      placeholder={t('placeholders.option')}
                      id="acquirer"
                    >
                      {acquirers &&
                        acquirers.map(op => {
                          return (
                            <option key={op.id} value={op.id}>
                              {op.businessName}
                            </option>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>

                <Divider borderColor="gray" my={4} />

                <Grid templateColumns="repeat(1, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.description}>
                    <FormLabel>{t('users.name')}:</FormLabel>
                    <Input
                      id="description"
                      placeholder={t('placeholders.name')}
                      {...register('description', {
                        required: 'Name required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.description && errors.description.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>

                <Divider borderColor="gray" my={4} />

                <Grid mt={2}>
                  <FormControl isInvalid={errors.email}>
                    <FormLabel>Email:</FormLabel>
                    <Input
                      id="email"
                      placeholder={t('placeholders.email')}
                      {...register('email', {
                        required: 'Email required',
                      })}
                    />

                    <FormErrorMessage>
                      {errors.email && errors.email.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>

                {/* 
                <Grid mt={2}>
                  <FormControl isInvalid={errors.profile}>
                    <FormLabel>Profile:</FormLabel>
                    <Select placeholder="Select option">
                      <option value="administrator">Administrator</option>
                      <option value="operator">Operator</option>
                      <option value="viewer">Viewer</option>
                    </Select>

                    <FormErrorMessage>
                      {errors.profile && errors.profile.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid> */}

                {/* <Grid mt={2}>
                  <FormControl isInvalid={errors.active}>
                    <FormLabel>Active:</FormLabel>
                    <Select placeholder="Select option">
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.active && errors.active.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid> */}

                <Divider borderColor="gray" my={4} />
              </Box>
              <Box p={3} justifyContent={'end'} display={'flex'}>
                <Button
                  variant="outline"
                  border={'1px solid #982549'}
                  color={'#982549'}
                  onClick={onClose}
                  type="submit"
                  mx={2}
                >
                  {t('buttons.cancel')}
                </Button>
                <Button isLoading={isSubmitting} type="submit">
                  {t('buttons.confirm')}
                </Button>
              </Box>
            </form>
          </DrawerContent>
        </Drawer>
        <Spacer />
      </Flex>
      <DataTable
        value={users}
        paginator
        rows={10}
        emptyMessage="Nenhum registro encontrado"
      >
        {columns.map(col => (
          <Column
            key={col.field}
            field={col.field}
            header={t(`users.${col.field}`)}
            width={col.width}
            sortable={col.sortable}
            body={col.body}
          />
        ))}
      </DataTable>

      <Drawer
        isOpen={isViewModalOpen}
        onClose={() => handleClose(false)}
        size={'md'}
        placement="right"
      >
        <DrawerOverlay />
        <DrawerContent backgroundColor={'white'}>
          <DrawerCloseButton />
          <DrawerHeader>{t('users.details')}</DrawerHeader>

          <DrawerBody>
            <VStack
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
              align="stretch"
            >
              <Box>
                <Text>{t('users.name')}</Text>
                <Input
                  isDisabled={true}
                  value={selectedUser?.description}
                  variant="filled"
                  placeholder="Filled"
                />
              </Box>
              <Box>
                <Text>Email</Text>
                <Input
                  value={selectedUser?.email}
                  isDisabled={true}
                  variant="filled"
                  placeholder="Filled"
                />
              </Box>
              <Box>
                <Text>{t('users.profile')}</Text>
                <Input
                  value={selectedUser?.profile}
                  isDisabled={true}
                  variant="filled"
                  placeholder="Filled"
                />
              </Box>
              <Box>
                <Text>{t('users.date')}</Text>
                <Input
                  value={moment(selectedUser?.date).format('DD/MM/YYYY')}
                  isDisabled={true}
                  variant="filled"
                  placeholder="active"
                />
              </Box>

              {/* <Box>
                <Text>Active</Text>
                <Input
                  isDisabled={true}
                  variant="filled"
                  placeholder="active"
                />
              </Box>

              <Box>
                <Button
                  isDisabled={changePassword}
                  onClick={() => handlePassword(true)}
                >
                  Change Password
                </Button>
              </Box> */}

              {changePassword && (
                <Box>
                  <Text>Password</Text>
                  <Input variant="filled" placeholder="Password" mb={5} />

                  <Text>Confirm Password</Text>
                  <Input variant="filled" placeholder="Confirm Password" />
                </Box>
              )}
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            {selectedUser && (
              <Button
                variant="outline"
                colorScheme={'#982549'}
                mx={3}
                onClick={() => handleClose(false)}
              >
                {t('buttons.cancel')}
              </Button>
            )}
            {!selectedUser && (
              <Button onClick={() => handleClose(false)}>
                {t('buttons.confirm')}
              </Button>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Modal
        isCentered
        isOpen={isDelModalOpen}
        onClose={() => setIsDelModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent background={'white'}>
          <ModalHeader> {t('modals.confirmDeletion')}</ModalHeader>
          <ModalBody>{t('users.infoSure')}</ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={() => setIsDelModalOpen(false)}>
              {t('buttons.cancel')}
            </Button>
            <Button mx={2} colorScheme="blue" mr={3} onClick={handleDelete}>
              {t('buttons.confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const Users = () => {
  return (
    <>
      <Flex
        ms={2}
        flexDirection="column"
        pt={{ base: '80px', md: '25px' }}
        p={8}
      >
        <Breadcrumb p={2} bg="#fef" color="#333">
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Muevy</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#">Users</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Box ms={12} bgColor={'white'} p={6} boxShadow={'md'} minH={'83vh'}>
        <Flex direction="column" pt={{ base: '120px', md: '5px' }}>
          <TableContainer>
            <DataProcess />
          </TableContainer>
        </Flex>
      </Box>
    </>
  );
};
