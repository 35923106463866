import {
  Input,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Icon,
  Text,
  Button,
} from '@chakra-ui/react';
import { LinkIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { useController } from 'react-hook-form';
import { useRef, useState } from 'react';

export const FileUpload = ({
  name,
  placeholder,
  acceptedFileTypes,
  control,
  children,
  isRequired = false,
  onFileChange
}) => {
  const inputRef = useRef();
  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
    rules: { required: isRequired },
  });
  const [error, setError] = useState('');

  const handleFileChange = e => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.name.split('.').pop().toLowerCase();
      if (fileType === 'csv' && file.size <= 3_145_728) {
        setError('');
        onChange(file);
        onFileChange(file);
      } else {
        setError('Please upload a CSV file or a file smaller than 3MB.');
      }
    }
  };

  const handleRemoveFile = () => {
    onChange(null);
    inputRef.current.value = '';
  };

  return (
    <FormControl isInvalid={invalid} isRequired>
      <FormLabel htmlFor="writeUpFile">{children}</FormLabel>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={LinkIcon} />
        </InputLeftElement>
        <input
          type="file"
          onChange={handleFileChange}
          accept={acceptedFileTypes}
          name={name}
          ref={inputRef}
          {...inputProps}
          style={{ display: 'none' }}
        />
        <Input
          placeholder={placeholder || 'Your file ...'}
          onClick={() => inputRef.current.click()}
          readOnly={true}
          value={(value && value.name) || ''}
        />
        {value && (
          <SmallCloseIcon
            cursor={'pointer'}
            mx={1}
            my={3}
            size="xs"
            onClick={handleRemoveFile}
          />
        )}
      </InputGroup>
      {error && (
        <Text fontSize="xs" color="red">
          {error}
        </Text>
      )}
    </FormControl>
  );
};

FileUpload.defaultProps = {
  acceptedFileTypes: '',
  allowMultipleFiles: false,
};

export default FileUpload;
