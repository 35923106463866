import { createAsyncThunk } from '@reduxjs/toolkit';
import { getToken } from '../../components/auth/UserData';
import axios from 'axios';
import baseUrl from '../../config/config';

export const getTransactions = createAsyncThunk('transactions/get', async ({dateFrom, dateTo}) => {
    const response = await axios.get(
        `${baseUrl}/portalapi/transaction?dateFrom=${dateFrom}&dateTo=${dateTo}`,
        {
            headers: {
                Authorization: `${getToken()}`,
            },
        }
    );
    
    let dataResponse = response.data;

    dataResponse.sort((a, b) =>{ 
        const firstDate = new Date(a.timestamp.split(' ')[0].split('-').reverse().join('-') + 'T' + a.timestamp.split(' ')[1]);
        const secondDate = new Date(b.timestamp.split(' ')[0].split('-').reverse().join('-') + 'T' + b.timestamp.split(' ')[1]);
        return secondDate - firstDate; 
    });

    for ( let i = 0; i < dataResponse.length; i++) {
        dataResponse[i].currencyCode = (dataResponse[i].currencyCode) ? dataResponse[i].currencyCode : "USD";
        dataResponse[i].amount = (dataResponse[i].amount) ? dataResponse[i].amount : 0;
        dataResponse[i].addressCitySender=dataResponse[i].sender.addressCity;
        dataResponse[i].addressCountrySender=dataResponse[i].sender.addressCountry;
        dataResponse[i].addressLineSender=dataResponse[i].sender.addressLine;
        dataResponse[i].nameSender=dataResponse[i].sender.name;
        dataResponse[i].senderAccountNumberSender=dataResponse[i].sender.senderAccountNumber;
        dataResponse[i].taxIdSender=dataResponse[i].sender.taxId;
        dataResponse[i].typeSender=dataResponse[i].sender.type;

        dataResponse[i].taxIdRecipient=dataResponse[i].recipient.taxId;
        dataResponse[i].firstNameRecipient=dataResponse[i].recipient.firstName;
        dataResponse[i].lastNameRecipient=dataResponse[i].recipient.lastName;
        dataResponse[i].bankCodeRecipient=dataResponse[i].recipient.bankCode;
        dataResponse[i].bankCodeTypeRecipient=dataResponse[i].recipient.bankCodeType;
        dataResponse[i].bankNameRecipient=dataResponse[i].recipient.bankName;
        dataResponse[i].accountNumberTypeRecipient=dataResponse[i].recipient.accountNumberType;
        dataResponse[i].accountNumberRecipient=dataResponse[i].recipient.accountNumber;
        dataResponse[i].accountNameRecipient=dataResponse[i].recipient.accountName;
        dataResponse[i].countryCodeRecipient=dataResponse[i].recipient.countryCode;
        
        dataResponse[i].currencyCodeRecipient=(dataResponse[i].recipient.currencyCode === "" || dataResponse[i].recipient.currencyCode === "null" || dataResponse[i].recipient.currencyCode === null || dataResponse[i].recipient.currencyCode !== 'EUR') ? "USD" : dataResponse[i].recipient.currencyCode;
        

        dataResponse[i].amountRecipient = (dataResponse[i].recipient.amount) ? dataResponse[i].recipient.amount : 0;
        dataResponse[i].addressCountryRecipient=dataResponse[i].recipient.addressCountry;
        dataResponse[i].addressStateRecipient=dataResponse[i].recipient.addressState;
        dataResponse[i].addressCityRecipient=dataResponse[i].recipient.addressCity;
        dataResponse[i].addressPostalCodeRecipient=dataResponse[i].recipient.addressPostalCode;
        dataResponse[i].addressLineRecipient=dataResponse[i].recipient.addressLine;

        delete dataResponse[i].sender;
        delete dataResponse[i].recipient;
    }

    return dataResponse; 
});

export const getTotalTransactions = createAsyncThunk('transactions/get', async ({dateFrom, dateTo}) => {
    const response = await axios.get(

        `${baseUrl}/portalapi/transaction?dateFrom=${dateFrom}&dateTo=${dateTo}`,
        {
            headers: {
                Authorization: `${getToken()}`,
            },
        }
    );

    const responseData          = response.data; 
    const quantityTransactions  = (responseData.length > 0) ? responseData.length : 0;
    let approvedTransactions    = 0;
    let rejectedTransactions    = 0;
    let amountbyCode = {};
    let currencies = [];

    for ( let i = 0; i < responseData.length; i++) {
        if(responseData[i].status === "SUCCESS"){
            approvedTransactions++;
        }else if (responseData[i].status === "REJECTED") {
            rejectedTransactions++;
        }
    }

    for( let i= 0; i < responseData.length; i++) {
        
        // const {amount , currencyCode } = responseData[i].recipient;
        const {currencyCode } = responseData[i].recipient;
        const {amount} = responseData[i];
        
        currencies.push({
            code: (currencyCode === "" || currencyCode === "null" || currencyCode === null || currencyCode !== 'EUR') ? "USD" : currencyCode,
            amount: (responseData[i].status === "SUCCESS") ? amount : 0
        });
    }

    currencies.forEach(item => {
        if (!amountbyCode[item.code]) {
            amountbyCode[item.code] = {
                amounts: [],
                total: 0
            };
        }

        amountbyCode[item.code].amounts.push(item.amount);
        
        amountbyCode[item.code].total += item.amount;
    });


    return {
        total_quantity_transactions: quantityTransactions,
        total_approved_transaction: approvedTransactions,
        total_rejected_transactions: rejectedTransactions,
        total_amount_by_currency_code: amountbyCode,

    }; 
});

export const formatCurrency = (amount, code) => {
    
    if(code === "null" || code === null || code === ""){
        return amount;

    }else{

        const options = {
            style: 'currency',
            currency: code, 
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 

        };

        return new Intl.NumberFormat('en-US', options).format(amount);
    }
}
  