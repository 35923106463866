import React from 'react';
import customTheme from './theme';
import '../src/assets/custom/table.scss';
import '../src/assets/custom/calendar.scss';
import { ChakraProvider, Flex, Box, Link, Image } from '@chakra-ui/react';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from 'react-router-dom';
import PrivateRoute from './components/routes/PrivateRoute';
import Navbar from './components/Navbar/NavbarComponent';
import { Login } from './pages/Login/Login';
import { Dashboard } from './pages/Home/Dashboard';
import { Webhook } from './pages/Acquirer/Product/Webhook/Webhook';
import { ForgotPassword } from './pages/ForgotPassword/ForgotPassword';
import { Users } from './pages/Muevy/Users/Users';
import { Acquirers } from '../src/pages/Muevy/Acquirers/Acquires';
import { Merchants } from './pages/Acquirer/Merchant/Merchant';
import { Product } from './pages/Acquirer/Product/Product/Product';
import { Oct } from './pages/Acquirer/Oct/Oct';
import { CreatePassword } from './pages/CreatePassword/CreatePassword';
import SideBar from './pages/Sidebar/Sidebar';
import { OctUnitary } from './pages/Acquirer/Oct/OctUnitary';
const Layout = ({ children }) => {
  const location = useLocation();
  const showNavbar = !['/login', '/forgotPassword', '/createPassword'].includes(
    location.pathname
  );

  return (
    <>
      {showNavbar && (
        <>
          <Flex
            direction="column"
            position="fixed"
            top="0"
            left="0"
            height="100vh"
            maxWidth="70px"
            bg="white"
            boxShadow={'0 4px 6px -1px rgba(0, 0, 0, 0.1)'}
          >
            <SideBar></SideBar>
          </Flex>

          <Flex
            direction={'row'}
            as="nav"
            align="center"
            justify="space-between"
            bg="white"
            color="black"
            left="70px"
            right="0"
            height="60px"
            boxShadow={'0 4px 6px -1px rgba(0, 0, 0, 0.1)'}
            transition="0.3s ease"
          >
            <Flex></Flex>
            <Link href="/" ms={36}>
              <Image cursor={'pointer'} src="/img/logo-muevy.svg" h={8} />
            </Link>
            <Navbar></Navbar>
          </Flex>
          <Box px={8} justifyContent={'center'}>
            <Box px={4}>{children}</Box>
          </Box>
        </>
      )}

      {!showNavbar && children}
    </>
  );
};

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route exact path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/createPassword" element={<CreatePassword />} />
            <Route element={<PrivateRoute />}>
              <Route exact path="/" element={<Dashboard />} />
              <Route
                exact
                path="acquirer/product/webhook"
                element={<Webhook />}
              />
              <Route exact path="muevy/users" element={<Users />} />
              <Route exact path="muevy/acquirers" element={<Acquirers />} />
              <Route exact path="acquirer/merchants" element={<Merchants />} />
              <Route exact path="acquirer/oct" element={<Oct />} />
              <Route
                exact
                path="acquirer/octunitary"
                element={<OctUnitary />}
              />
              <Route exact path="acquirer/products" element={<Product />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Routes>
        </Layout>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
