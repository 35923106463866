export const getProfle = () => {
    return sessionStorage.getItem('profile') || null;
}

export const getToken = () => {
    return sessionStorage.getItem('token') || null;
}

export const getUsername = () => {
    return sessionStorage.getItem('username') || null;
}

export const setUserSession = (username, token,  profile) => {
	sessionStorage.setItem('username', username);
	sessionStorage.setItem('token', token);
	sessionStorage.setItem('profile', profile);
}

export const removeUserSession = () => {
	sessionStorage.removeItem('username');
	sessionStorage.removeItem('token');
	sessionStorage.removeItem('profile');
}	
